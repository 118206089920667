import { Injectable } from '@angular/core';
import auth from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from '../model/user.model';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private isLoggedIn$ = new BehaviorSubject<boolean>(false);
  private user$ = new BehaviorSubject<User | undefined>(undefined);
  protected userData: any | null = null; // Save logged in user data

  constructor(private afAuth: AngularFireAuth) {
    this.afAuth.authState.subscribe((usr) => {
      if (usr) {
        this.isLoggedIn$.next(true);
        this.user$.next({ name: usr.displayName, email: usr.email, photoURL: usr.photoURL } as User);
      }
    });
  }

  doGoogleLogin(): Promise<any> {
    const provider = new auth.auth.GoogleAuthProvider();
    provider.addScope('profile');
    provider.addScope('email');
    return this.doProviderLogin(provider);
  }

  getUser(): Observable<User | undefined> {
    return this.user$;
  }

  isLoggedIn(): Observable<boolean>{
    return this.isLoggedIn$;
  }

  logout(): Promise<void> {
    return this.afAuth.signOut().then(() => {
      this.isLoggedIn$.next(false);
      this.user$.next(undefined);
    });
  }

  private doProviderLogin(provider: auth.auth.AuthProvider): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.afAuth.signInWithPopup(provider).then((res) => {
        this.isLoggedIn$.next(true);
        this.userData = res;
        resolve(res);
      });
    });
  }
}
